import React, { FC, useContext, useEffect, useMemo, useRef } from 'react'
import { Box, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useLocation from 'react-use/lib/useLocation'
import throttle from 'lodash/throttle'
import './index.css'
import { ContentfulEntry } from '../../components/Critique/ContentfulEntry'
import { CritiqueResultsBanner } from './CritiqueResultsBanner'
import {
  CritiqueContextProvider,
  CritiqueDataContext,
  CritiqueSelect,
  ICritiqueProfile,
} from './useCritiqueCopy'
import { CritiquePageProvider } from './CritiqueProvider'
import { useCritiqueStyles } from './styles'
import {
  useMixpanelCTAClickTracking,
  useMpEventTrackOn,
} from '@talentinc/gatsby-theme-ecom/utils/mixpanel'
import {
  MixpanelEventTypes,
  MixpanelEvents,
} from '@talentinc/gatsby-theme-ecom/types/mixpanel'
import { updateHrefs } from '@talentinc/gatsby-theme-ecom/utils/dom'
import {
  SessionAPIResponse,
  useSessionQuery,
} from '@talentinc/gatsby-theme-ecom/components/Providers/UserSession'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'
import useDiscountToken from '@talentinc/gatsby-theme-ecom/hooks/useDiscountToken'

type WayPoint = 25 | 50 | 75 | 100

const SCROLL_WAY_POINTS: WayPoint[] = [25, 50, 75, 100]

export type TemplateProps = {
  rows: any[]
  footer?: any[]
  variant?: string
}

const updateDiscountTokenLinks = (
  data: SessionAPIResponse | undefined,
  dt?: string | null
) => {
  const isRIOSession = data?.partnerCoBrand?.name === 'TopResume + Resume.io'
  if (isRIOSession) {
    const rioLink = `https://resumeio.topresume.com/resume-writing?dt=${
      dt || 'TRCIO2023'
    }`
    updateHrefs('../resume-writing', rioLink)
    updateHrefs('/resume-writing', rioLink)
  } else {
    if (dt) {
      updateHrefs('../resume-writing', `../resume-writing?dt=${dt}`)
      updateHrefs('/resume-writing', `/resume-writing?dt=${dt}`)
    }
  }
}

const updateLinksV3LeadBands = (pathname: string) => {
  const linkURL = {
    '/new/resumecritiqueview-v3-1': 'resume-writing-total-job-search-2',
    '/new/resumecritiqueview-v3': 'resume-writing-total-job-search-3',
  }[pathname] as string

  linkURL && updateHrefs('../new/resume-writing-total-job-search', linkURL)
  linkURL &&
    updateHrefs(
      '../new/resume-writing-total-job-search?tab=job_search',
      linkURL + '?tab=job_search'
    )
  linkURL && updateHrefs('/resume-writing', linkURL)
}

const fadeFixSidebar = async () => {
  const critiqueSidebar = document.querySelector('.critique-sidebar')
  const nextStepsSection = document.querySelector(
    '#critique-section-nextsteps'
  ) as any

  if (!critiqueSidebar || !nextStepsSection) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    fadeFixSidebar()
  } else {
    critiqueSidebar?.setAttribute(
      'style',
      `display: flex; flex-direction: column; gap: 24px; height: unset; max-height: ${
        nextStepsSection?.offsetTop - 100
      }px`
    )
  }
}

const CritiqueTemplateContent: FC<{
  rows: TemplateProps['rows']
  footer: TemplateProps['footer']
  variant: string
}> = ({ rows, footer, variant }) => {
  const critiqueStyles = useCritiqueStyles()
  const { classes, cx } = useStyles()
  const { pathname } = useLocation()
  const discountToken = useDiscountToken()
  const {
    active: [, activeCritique, { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const { data } = useSessionQuery()

  const isV3 = variant === 'v3'

  useEffect(() => {
    updateDiscountTokenLinks(data, discountToken)
  }, [data, activeCritique])

  useEffect(() => {
    isV3 && pathname && updateLinksV3LeadBands(pathname)
  }, [isV3, pathname, activeCritique])

  return (
    <div
      className={cx(classes.content, {
        'perfect-critique': isPerfect,
      })}
    >
      <Container
        className={cx(classes.entries, critiqueStyles.classes.rowsContainer)}
        disableGutters
      >
        {rows &&
          rows?.map((row) => <ContentfulEntry key={row.contentful_id} {...row} />)}
      </Container>
      {footer &&
        footer?.map((row) => <ContentfulEntry key={row.contentful_id} {...row} />)}
      <CritiqueResultsBanner />
    </div>
  )
}

export const Template: FC<TemplateProps> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const { rows, footer, variant = 'v1' } = props
  const { classes, cx } = useStyles()
  const firedScrollEvents = useRef<Record<number, boolean>>({})
  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const critiqueEventTrackOn = useMpEventTrackOn(MixpanelEventTypes.CritiqueEvent)
  const telemetry = useBETelemetry()

  const isV2 = variant === 'v2'
  const isV3 = variant === 'v3'

  useEffect(() => {
    setTimeout(() => {
      mainRef.current?.classList.add('loaded')
      telemetry.track({
        event: 'critique_viewed_fe',
        properties: { event_type: 'critique_event_fe' },
      })
    }, 250)
    // eslint-disable-next-line
  }, [])

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const scrollPercent =
          (window.scrollY /
            (window.document.body.offsetHeight - window.innerHeight)) *
          100

        SCROLL_WAY_POINTS.forEach(function (waypoint) {
          if (
            scrollPercent >= waypoint - 1 &&
            scrollPercent <= waypoint + 1 &&
            !firedScrollEvents.current[waypoint]
          ) {
            firedScrollEvents.current[waypoint] = true

            handleMpCTAClickTracking(null, '', {
              eventName: MixpanelEvents[`CritiqueScroll${waypoint}`],
              trackingData: {
                event_type: MixpanelEventTypes.CritiqueEvent,
              },
              trackOn: critiqueEventTrackOn,
            })
          }
        })
      }, 100),
    [critiqueEventTrackOn, handleMpCTAClickTracking]
  )

  useEffect(() => {
    if (isV3) fadeFixSidebar()
  }, [isV3, rows])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    handleScroll()
  }, [handleScroll])

  return (
    <CritiqueContextProvider>
      <CritiquePageProvider variant={variant}>
        <Box
          ref={mainRef}
          className={cx(classes.root, 'critique-page', isV2 && 'v2', isV3 && 'v3')}
          component="main"
        >
          <CritiqueSelect />
          <CritiqueTemplateContent rows={rows} footer={footer} variant={variant} />
        </Box>
      </CritiquePageProvider>
    </CritiqueContextProvider>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },

  entries: {
    [theme.breakpoints.down(426)]: {
      padding: '0 24px',
    },
  },
}))
