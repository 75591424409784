import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  useMediaQuery,
  Theme,
} from '@mui/material'
import { makeStyles, withStyles } from 'tss-react/mui'
import { ExpandMore } from '@mui/icons-material'
import usePageData from '@talentinc/gatsby-theme-ecom/hooks/usePageData'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import NavigationGroupLink from '@talentinc/gatsby-theme-ecom/components/NavigationGroup/NavigationGroupLink'
import usePageMeasurements from '@talentinc/gatsby-theme-ecom/hooks/usePageMeasurements'
import SocialMediaIcons from '@talentinc/gatsby-theme-ecom/components/SocialMediaIcons/SocialMediaIcons'
import { TargetOptions } from '@talentinc/gatsby-theme-ecom/types/page'
import { withTwoPassRenderer } from '../../hocs/withTwoPassRenderer'
import NewsLetterForm from '../Forms/NewsletterForm'
import useUser from '../../hooks/useUser'

interface FooterProps {
  className?: string[]
  useSimulatedPageLoad?: boolean
}

const Footer: React.FC<FooterProps> = ({
  className = [],
  useSimulatedPageLoad = true,
}) => {
  const { classes, cx } = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [showNewsletterForm, setShowNewsletterForm] = React.useState(false)
  const [isPageLoaded, setIsPageLoaded] = useState(!useSimulatedPageLoad)

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  const { footer: footerData } = usePageData()
  const {
    lead_auth_token: leadAuthToken,
    partnerCoBrand,
    isLoading,
  } = useUser()
  const brand = useBrand()
  const {
    footer: [footerRef],
  } = usePageMeasurements()

  const isSmallScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down(768)
  )

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up(1440))

  useEffect(() => {
    // Show the Newsletter form in Footers where `showNewsletterForm` is set to Yes
    // and the user isn't logged in
    setShowNewsletterForm(!!footerData?.showNewsletterForm && !leadAuthToken)

    // Simulate page loading completion
    if (useSimulatedPageLoad) {
      setTimeout(() => {
        setIsPageLoaded(true)
      }, 1500)
    }
  }, [leadAuthToken, footerData?.showNewsletterForm])

  if (!footerData) {
    return null
  }

  return (
    <Box
      ref={footerRef}
      className={cx(classes.footer, {
        [classes.critique]: className.includes('critique'),
      })}
      component="footer"
    >
      <Box>
        <Box className={classes.upper}>
          <Box className={classes.navigationContainer}>
            {footerData.navigation.map((navGroup) =>
              isSmallScreen ? (
                <Accordion
                  expanded={expanded === navGroup.title}
                  onChange={handleChange(navGroup.title)}
                  square
                  key={navGroup.title}
                >
                  <AccordionSummary
                    itemProp="name"
                    expandIcon={<ExpandMore />}
                    disableRipple
                    className={classes.navigationTitle}
                  >
                    {navGroup.title}
                  </AccordionSummary>
                  <AccordionDetails>
                    {navGroup.links.map((link) => (
                      <NavigationGroupLink
                        key={link.contentful_id}
                        link={link}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Box
                  className={classes.navigation}
                  component="nav"
                  key={navGroup.title}
                >
                  <Typography
                    className={classes.navigationTitle}
                    component="h4"
                    variant="overline"
                  >
                    {navGroup.title}
                  </Typography>
                  {navGroup.links.map((link) => (
                    <NavigationGroupLink
                      className={classes.navigationLink}
                      key={link.contentful_id}
                      link={link}
                    />
                  ))}
                </Box>
              )
            )}
          </Box>
          <Box className={classes.upperLeft}>
            <a className={classes.logo} href="/" style={{ maxWidth: '15rem' }}>
              <img
                alt={partnerCoBrand?.name ?? brand.name}
                src={partnerCoBrand?.logoInverse ?? brand.whiteLogo?.file.url}
                width="100%"
                height="100%"
              />
            </a>
            <SocialMediaIcons
              containerClass={classes.socialContainer}
              facebook
              twitter
              linkedIn
              instagram
            />
          </Box>
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.navigationBottom} component="nav">
        <Box>
          {footerData.bottomNavigation &&
            footerData.bottomNavigation.map((navGroup) =>
              navGroup.links.map((link) => {
                if (
                  'text' in link &&
                  link.text === 'Do Not Sell' &&
                  brand.name?.toLowerCase().includes('topcv')
                ) {
                  return null
                }

                if ('url' in link) {
                  return (
                    <NavigationGroupLink
                      className={classes.navigationLinkBottom}
                      key={link.contentful_id}
                      link={{
                        ...link,
                        openTheLinkIn: isDesktop
                          ? TargetOptions.NewTab
                          : TargetOptions.SameTab,
                      }}
                    />
                  )
                }

                return (
                  <NavigationGroupLink
                    className={classes.navigationLinkBottom}
                    key={link.contentful_id}
                    link={link}
                  />
                )
              })
            )}
        </Box>
        <Typography
          className={classes.copyright}
          component="p"
          variant="caption"
        >
          {`© ${new Date().getFullYear()} ${brand.name}`}, All Rights Reserved.
        </Typography>
      </Box>
      {!isLoading && showNewsletterForm && <NewsLetterForm />}
    </Box>
  )
}

const Accordion = withStyles(MuiAccordion, (theme) => ({
  root: {
    borderBottom: `0.5px solid ${theme.palette.secondary.dark}`,
    backgroundColor: 'transparent',

    '&::before': {
      height: 0,
    },

    '&:first-child': {
      borderTop: `0.5px solid ${theme.palette.secondary.dark}`,
    },
  },
  expanded: {
    '&&': {
      margin: 0,
    },

    '&:last-child': {
      marginBottom: '3.5rem',
    },
  },
}))

const AccordionSummary = withStyles(MuiAccordionSummary, (theme) => ({
  root: {
    paddingBlock: '1rem',
    paddingInline: 0,
    color: theme.palette.secondary.dark,

    '&:focus': {
      outline: 0,
    },
  },
  content: {
    marginBlock: 0,
  },
  expanded: {
    '&&': {
      minHeight: 0,
      margin: 0,
    },
  },
  expandIconWrapper: {
    '&&': {
      paddingBlock: 0,
      marginRight: '-18px',
    },
  },
}))

const AccordionDetails = withStyles(MuiAccordionDetails, () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    padding: '0 0 1rem 0',
  },
}))

const useStyles = makeStyles()((theme) => ({
  badge: {
    height: '3.8476rem',
    objectFit: 'contain',
    width: '6.71875rem',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  badgeMobile: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'initial',
      marginTop: '1.47em',
    },
  },
  contact: {
    fontSize: '1em',
    textDecoration: 'none',
  },
  copyright: {
    opacity: '0.9',
    fontSize: '0.75rem',
    color: theme.palette.secondary.dark,
  },

  divider: {
    borderColor: theme.palette.text.secondary,

    [theme.breakpoints.up('lg')]: {
      margin: '.97em 0 1.25em',
    },
  },
  footer: {
    backgroundColor: theme.palette.newColors.blackBackground,
    color: theme.palette.background.default,
    padding: '0 6% 1.25em 6%',

    '& a': {
      color: theme.colors.neutral[30],
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.background.default,
      },
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '2.03em',
    },
    '@media (min-width: 1456px)': {
      padding:
        '0 calc((100% - 91rem)/2 + 5.5rem) 1.25em calc((100% - 91rem)/2 + 5.5rem)',
    },

    [theme.breakpoints.down(768)]: {
      paddingTop: '2rem',
    },
  },
  critique: {
    backgroundColor: theme.colors.neutral[90],

    '& hr': {
      backgroundColor: theme.colors.neutral[50],
    },
  },
  logo: {
    display: 'flex',
    '& img': {
      maxHeight: '48px',
      maxWidth: '14rem',
    },
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down(768)]: {
      alignItems: 'center',
      margin: '1.27rem 0',
    },
  },
  navigationBottom: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
    color: theme.palette.secondary.dark,

    [theme.breakpoints.down('lg')]: {
      marginTop: '1em',
      alignItems: 'center',
    },
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '60%',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
      maxWidth: 'initial',
      paddingLeft: '0',
    },

    [theme.breakpoints.down(768)]: {
      flexDirection: 'column',
      marginBottom: '3.5rem',
    },
  },
  navigationLink: {
    margin: '.25rem 0',

    '&:hover': {
      color: theme.palette.background.default,
    },
  },
  navigationLinkBottom: {
    marginRight: '0.62em',
    marginBottom: '0.2rem',
    fontWeight: 600,

    '&:last-child': {
      marginRight: 0,
    },
  },
  navigationTitle: {
    color: theme.colors.neutral[50],
    fontSize: '.75em',
    letterSpacing: '0.05em',
    fontWeight: 600,
    textTransform: 'uppercase',

    [theme.breakpoints.up('md')]: {
      marginBottom: '0.25rem',
    },
  },
  socialContainer: {
    margin: '1em 0.386em 1em 0',
    [theme.breakpoints.down('lg')]: {
      margin: '1.5em 0 1.06em',
    },
  },
  upper: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '13.13em',
    borderTop: `1px solid ${theme.colors.neutral[50]}`,
    paddingTop: '2rem',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: '2rem',
    },
    [theme.breakpoints.down(768)]: {
      borderTop: 'none',
    },
  },
  upperLeft: {
    minWidth: '13.13em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    paddingBottom: '.25rem',
    width: '100%',

    [theme.breakpoints.up(768)]: {
      paddingBottom: '.75rem',
    },

    [theme.breakpoints.up('md')]: {
      paddingBottom: '1.125rem',
    },

    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-end',
    },
  },
}))

export default withTwoPassRenderer(Footer)
